<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <card
        class="card-login card-white shadow-lg mb-2">
        <template slot="header">
          <img :src="imgCardLogin" alt=""/>
          <img :src="imgLogo" class="m-2" alt=""/>
        </template>
        <login-form @onLoginUser="loginUser" v-show="showLogin"/>

        <div class="text-center" v-show="showLogin">
          <h6>
            <a class="link footer-link" @click="activateRecoveryPassword">
              {{ $t('auth.forget_password') }} ?
            </a>
          </h6>
        </div>

        <load-config-component v-show="showLoadSettings" ref="loadConfigComponent"/>
        <update-password-form v-show="showUpdateTempPassword"
                              @onSkipUpdate="redirectByRole"
                              @onUpdatePassword="updatePassword"/>
        <recovery-password-form @onRecoveryPassword="recoveryPassword" v-show="showRecoveryPassword"/>

        <div class="text-center" v-show="showRecoveryPassword">
          <p class="small text-body">{{ $t('page.recovery_password_info') }}</p>
          <h6 class="mt-2">
            <a class="link footer-link" @click="activateLoginForm">
              {{ $t('auth.login') }}
            </a>
          </h6>
        </div>
      </card>
      <div class="small text-center">
        Version {{ version }}
      </div>
    </div>
  </div>
</template>
<script>

import {extend} from "vee-validate";
import {required, min} from "vee-validate/dist/rules";
import LoginForm from "@/pages/Login/forms/LoginForm";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {endpoints} from "@/routes/endpoints";
import LoadConfigComponent from "@/pages/Login/components/LoadConfigComponent";
import UpdatePasswordForm from "@/pages/Login/forms/UpdatePasswordForm";
import RecoveryPasswordForm from "@/pages/Login/forms/RecoveryPasswordForm";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {permissionService} from "@/util/permission-service";

extend("min", min);
extend("required", required);

export default {
  components: {OctoIcon, UpdatePasswordForm, LoadConfigComponent, LoginForm, RecoveryPasswordForm},
  data() {
    return {
      imgLogo: require('@/assets/images/logo.png'),
      imgCardLogin: require('@/assets/images/card-login-header.png'),
      showLogin: false,
      showRecoveryPassword: false,
      showLoadSettings: false,
      showUpdateTempPassword: false,
      version: process.env.VUE_APP_VERSION,
      permissionService: permissionService,
    };
  },
  mounted() {
    this.showLogin = true;
    this.showLoadSettings = false;
    this.showUpdateTempPassword = false;
    this.showRecoveryPassword = false;

  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {

    ...mapActions({
      getCurrentUser: 'auth/getCurrentUser',
      logout: 'auth/logout'
    }),

    ...mapMutations({
      setAccessToken: 'auth/setAccessToken',
    }),

    async loginUser(data) {
      try {
        this.$fullLoading.show();
        // await this.$api.get(endpoints.SANCTUM_CSRF_COOKIE);
        const responseLogin = await this.$api.post(endpoints.USER_LOGIN, data);
        this.setAccessToken(responseLogin?.data?.access_token);
        this.$fullLoading.hide();
        this.showLogin = false;
        this.showLoadSettings = true;
        await this.$refs.loadConfigComponent.startLoadConfigs();
        if (responseLogin?.data?.data?.temp_password) {
          this.showLoadSettings = false;
          this.showUpdateTempPassword = true;
          this.$notify({type: 'warning', message: this.$t('notifications.update_password')});
        } else {
          this.redirectByRole();
        }
      } catch (e) {
        this.$fullLoading.hide();
        this.$notify({type: 'danger', message: this.$t('notifications.invalid_credentials')});
      }
    },

    updatePassword: function (password) {
      this.$fullLoading.show();

      this.$api.post(endpoints.USER_UPDATE_PASSWORD, {
        password: password
      }).then(() => {
        this.$fullLoading.hide();
        this.redirectByRole();
      }).catch(() => {
        this.$fullLoading.hide();
        this.$notify({type: 'danger', message: this.$t('notifications.password_not_updated')});
      })
    },

    activateLoginForm: function () {
      this.showLogin = true;
      this.showRecoveryPassword = false;
    },

    activateRecoveryPassword: function () {
      this.showLogin = false;
      this.showRecoveryPassword = true;
    },

    async recoveryPassword(data) {
      try {
        await this.$api.get(endpoints.SANCTUM_CSRF_COOKIE);

        await this.$api.post(endpoints.USER_RECOVERY_PASSWORD, {
          username: data.username,
          email: data.email
        });

        this.$notify({type: 'success', message: this.$t('notifications.recovery_password_sent')});
        this.activateLoginForm();

      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.recovery_password_error')});
      }
    },
    redirectByRole() {
      const roleNames = this.$_.map(this.currentUser.roles, role => {
        return role.name;
      });

      if (roleNames.indexOf(permissionService.ADMIN_ROLE) !== -1) {
        return this.$router.push({name: 'users.index'});
      }

      if (roleNames.indexOf(permissionService.CALL_CENTER_MANAGER_ROLE) !== -1) {
        return this.$router.push({name: 'leads.dashboard'});
      }

      if (roleNames.indexOf(permissionService.SALES_MANAGER_ROLE) !== -1) {
        return this.$router.push({name: 'deals.dashboard'});
      }

      if (roleNames.indexOf(permissionService.CALL_CENTER_OPERATOR_ROLE) !== -1) {
        return this.$router.push({name: 'operator.handle_lead'});
      }

      if (roleNames.indexOf(permissionService.TEACHING_MANAGER_ROLE) !== -1) {
        return this.$router.push({name: 'courses.index'});
      }

      if (roleNames.indexOf(permissionService.TUTOR_ROLE) !== -1) {
        return this.$router.push({name: 'didactics.classrooms.index'});
      }

      if (roleNames.indexOf(permissionService.SALES_ROLE) !== -1) {
        return this.$router.push({name: 'deals.in_progress'});
      }

      if (roleNames.indexOf(permissionService.ACCOUNTANT_ROLE) !== -1) {
        return this.$router.push({name: 'post_selling.index'});
      }

      this.logout();
    }
  }
};
</script>
<style lang="scss" scoped>

</style>
