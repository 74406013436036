<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(loginUser)">
      <div>
        <ValidationProvider
          name="username"
          rules="required"
          v-slot="{ passed, failed, errors }"
        >
          <base-input
            required
            v-model="username"
            type="text"
            addon-left-icon="user"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <ValidationProvider
          name="password"
          rules="required|min:5"
          v-slot="{ passed, failed, errors }"
        >
          <base-input
            required
            v-model="password"
            addon-left-icon="padlock"
            type="password"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>
      </div>
      <base-button native-type="submit" class="mb-3" size="lg" block>
        {{ $t('auth.login') }}
      </base-button>
    </form>
  </ValidationObserver>
</template>

<script>
import {extend} from "vee-validate";
import {required, min} from "vee-validate/dist/rules";

extend("min", min);
extend("required", required);

export default {
  name: "LoginForm",
  data() {
    return {
      username: "",
      password: ""
    };
  },

  beforeMount() {
    if( process.env.NODE_ENV === 'development') {
      this.username = "di.mascio.alessandra";
      this.password = "click"
    }
  },
  methods: {
    loginUser() {
      this.$emit('onLoginUser', {
        username: this.username,
        password: this.password
      })
    }
  }
}
</script>

<style scoped>

</style>
