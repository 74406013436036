<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(recoveryPassword)">
      <div>
        <ValidationProvider
          name="username"
          rules="required"
          v-slot="{ passed, failed, errors }"
        >
          <base-input
            required
            v-model="username"
            type="text"
            maxlength="50"
            addon-left-icon="user"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <ValidationProvider
          name="email"
          rules="required|email"
          v-slot="{ passed, failed, errors }"
        >
          <base-input
            required
            v-model="email"
            addon-left-icon="edit"
            type="text"
            maxlength="100"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>
      </div>
      <base-button native-type="submit" class="mb-3" size="lg" block>
        {{ $t('auth.send_forget_password') }}
      </base-button>

    </form>
  </ValidationObserver>
</template>

<script>
import {extend} from "vee-validate";
import {required, min} from "vee-validate/dist/rules";

extend("min", min);
extend("required", required);

export default {
  name: "RecoveryPasswordForm",
  data() {
    return {
      username: "",
      email: ""
    };
  },

  methods: {
    recoveryPassword() {
      this.$emit('onRecoveryPassword', {
        username: this.username,
        email: this.email
      })

    }
  }
}
</script>

<style scoped>

</style>
