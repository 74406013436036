var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"text-primary text-center text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('common.update_password_temp'))+" ")]),_c('base-alert',{attrs:{"type":"info"}},[_c('p',{staticClass:"text-center text-white"},[_vm._v(" "+_vm._s(_vm.$t('auth.password_rules.must_contain'))+" ")]),_c('ul',{staticClass:"small mb-0"},[_c('li',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t('auth.password_rules.lowercase'))+" ")]),_c('li',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t('auth.password_rules.uppercase'))+" ")]),_c('li',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t('auth.password_rules.digit'))+" ")]),_c('li',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t('auth.password_rules.special_character'))+" ")])])]),_c('ValidationObserver',{ref:"passwordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updatePassword)}}},[_c('div',[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","addon-left-icon":"padlock","type":"password","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"name":"confirm_password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","addon-left-icon":"padlock","type":"password","error":errors[0]},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})],1),_c('base-button',{staticClass:"mb-2",attrs:{"native-type":"submit","size":"lg","block":""}},[_vm._v(" "+_vm._s(_vm.$t('auth.update_password'))+" ")])],1)]}}])}),_c('base-button',{attrs:{"type":"warning","size":"lg","block":""},on:{"click":function($event){return _vm.$emit('onSkipUpdate')}}},[_vm._v(" "+_vm._s(_vm.$t('auth.skip_update_password'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }