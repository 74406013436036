<template>
  <div>
    <p class="text-primary text-center text-uppercase">
      {{ $t('common.update_password_temp') }}
    </p>
    <base-alert type="info">
      <p class="text-center text-white">
        {{ $t('auth.password_rules.must_contain')}}
      </p>
      <ul class="small mb-0">
        <li class="text-white">
          {{ $t('auth.password_rules.lowercase') }}
        </li>
        <li class="text-white">
          {{ $t('auth.password_rules.uppercase') }}
        </li>
        <li class="text-white">
          {{ $t('auth.password_rules.digit') }}
        </li>
        <li class="text-white">
          {{ $t('auth.password_rules.special_character') }}
        </li>
      </ul>
    </base-alert>
    <ValidationObserver v-slot="{ handleSubmit }" ref="passwordForm">
      <form @submit.prevent="handleSubmit(updatePassword)">
        <div>
          <ValidationProvider
            name="password"
            rules="required|min:5"
            vid="confirmation"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              v-model="password"
              addon-left-icon="padlock"
              type="password"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </div>
        <div>
          <ValidationProvider
            name="confirm_password"
            rules="required|confirmed:confirmation"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              v-model="confirmPassword"
              addon-left-icon="padlock"
              type="password"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </div>
        <base-button native-type="submit" class="mb-2" size="lg" block>
          {{ $t('auth.update_password') }}
        </base-button>
      </form>
    </ValidationObserver>
    <base-button @click="$emit('onSkipUpdate')" type="warning" size="lg" block>
      {{ $t('auth.skip_update_password') }}
    </base-button>
  </div>
</template>

<script>
import {extend} from "vee-validate";
import {min, required, confirmed} from "vee-validate/dist/rules";
import BaseAlert from "../../../components/BaseAlert";

extend("min", min);
extend("required", required);
extend("confirmed", confirmed);

export default {
  name: "UpdatePasswordForm",
  components: {BaseAlert},
  data() {
    return {
        confirmPassword: '',
        password: '',
    }
  },
  methods: {
    updatePassword() {
      if (this.$refs.passwordForm.validate()) {
        this.$emit('onUpdatePassword', this.password);
      }
    }
  }
}
</script>

<style scoped>

</style>
