<template>
  <div>
    <p class="text-primary text-center text-uppercase">
      {{ $t('common.load_settings') }}
    </p>
    <div
      class="mb-3 text-body"
      v-for="progress in progresses" v-bind:key="progress.name">
      <base-progress
        :label="progress.label"
        :value="progress.value"
        value-position="right"
        :type="progress.variant"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BaseProgress from "@/components/BaseProgress";

export default {
  name: "LoadConfigComponent",
  components: {BaseProgress},
  data() {
    return {
      progresses: {
        user: {
          name: 'user',
          value: 0,
          variant: 'info',
          label: this.$t('common.user_data')
        },
        roles: {
          name: 'roles',
          value: 0,
          variant: 'success',
          label: this.$t('common.roles')
        },
        settings: {
          name: 'settings',
          value: 0,
          variant: 'danger',
          label: this.$t('common.general_settings')
        },
        otherData: {
          name: 'otherData',
          value: 0,
          variant: 'primary',
          label: this.$t('common.other_data')
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      countries: 'common/countries',
      regions: 'common/regions',
      provinces: 'common/provinces'
    })
  },

  methods: {
    ...mapActions({
      getRoles: 'auth/getRoles',
      getSettings: 'common/getSettings',
      getCurrentUser: 'auth/getCurrentUser',
      getUsers: 'common/getUsers',
      getCampaigns: 'common/getCampaigns',
      getCourses: 'common/getCourses',
      getPlaces: 'common/getPlaces',
      getProviders: 'common/getProviders',
      getSubjects: 'common/getSubjects',
      getCourseModules: 'common/getCourseModules',
      getLocations: 'common/getLocations',
      getEmailTemplates: 'common/getEmailTemplates',
    }),

    async startLoadConfigs() {
      this.progresses.user.value = 30;
      await this.getUsers();
      this.progresses.user.value = 60;
      await this.getCurrentUser();
      this.progresses.user.value = 100;

      this.progresses.roles.value = 50;
      await this.getRoles();
      this.progresses.roles.value = 100;

      this.progresses.settings.value = 50;
      await this.getSettings();
      this.progresses.settings.value = 100;


      this.progresses.otherData.value = 10;
      this.progresses.otherData.label = this.$t('common.locations');
      await this.getLocations();
      this.progresses.otherData.value = 20;
      this.progresses.otherData.label = this.$t('common.subjects');
      await this.getSubjects();
      this.progresses.otherData.value = 30;
      this.progresses.otherData.label = this.$t('common.course_modules');
      await this.getCourseModules();
      this.progresses.otherData.value = 40;
      this.progresses.otherData.label = this.$t('common.courses');
      await this.getCourses();
      this.progresses.otherData.value = 70;
      this.progresses.otherData.label = this.$t('common.campaigns');
      await this.getCampaigns();
      this.progresses.otherData.value = 80;
      this.progresses.otherData.label = this.$t('common.places');
      if (this.$_.isEmpty(this.countries) || this.$_.isEmpty(this.provinces) || this.$_.isEmpty(this.regions)) {
        await this.getPlaces();
      }
      this.progresses.otherData.value = 90;
      this.progresses.otherData.label = this.$t('common.providers');
      await this.getProviders();

      this.progresses.otherData.value = 95;
      this.progresses.otherData.label = this.$t('common.email_templates');
      await this.getEmailTemplates();

      this.progresses.otherData.value = 100;
    }
  }
}
</script>

<style scoped>

</style>
